import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import { useRedirectIfAuthLogic } from '../LoginPage/useAuthLogic';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { ssoLogin } from '../../../../actions/auth';
import axios from 'axios';
import * as types from '../../../../constants';

export default function SSOCookiePage({lang}) {

  const dispatch = useDispatch();

  const redirectPath = useRedirectIfAuthLogic(lang);
  
  useEffect(() => {
    const data = {
      access_token: Cookies.get('access_token'),
      token_type: 'Bearer',
      refresh_token: Cookies.get('contentoo_session'),
    }

    if(data.access_token) {
      dispatch(ssoLogin(data));
    }
  }, [])

  // REDIRECT IF USER IS AUTHENTICATED (redirectPath is not null)
  if (redirectPath) {
   
    return <Redirect to={redirectPath} />;
  } else {
    dispatch({
      type: types.LOGIN_FAILURE,
      payload: "Make sure you have an account with the same email before signing in with Google/Microsoft",
    });
    return <Redirect to={`/${lang}/login`} />;
  }
}
