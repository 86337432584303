import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserStates } from '../../../../utils';
import { OrNull } from '../../../../types';
import { useAuthPermissions } from '../../../../hooks';

export const useRedirectIfAuthLogic = (lang: string): OrNull<string> => {
  const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const { hasFreelancerRole } = useAuthPermissions();

  const userState = user?.state ?? '';
  const redirect = userState === UserStates.ACTIVE
    ? 'dashboard/projects/active'
    : userState === UserStates.PENDING_APPROVAL
      ? `freelancer-profile/${user?.id}`
      : userState === UserStates.ONBOARDING
        ? 'lets-go'
        : '';

  // TODO check freelancer redirect url
  if (isAuthenticated) {
    if (hasFreelancerRole) {
      return `/${lang}/${redirect}`;
    } else {
      return `/${lang}/dashboard/projects/active`;
    }
  }

  return null;
};
